body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a2a2a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.baloon {
  margin: 5px !important;
}

.op-icon {
  float: right;
}

.feature-button {
  margin-top: 5px !important;
}

.card-number-left {
  margin-bottom: 5px !important;
}

.card-number-right {
  margin-bottom: 5px !important;
  float: right;
}

.teal {
  color: #006767;
}

.teal-bg {
  background-color: #006767 !important;
  color: white !important;
}

.teal:hover {
  color: #0aeeee !important;
}

.black-bg {
  background-color: black !important;
  color: white !important;
}

.grey-bg {
  background-color: #C5C5C5 !important;
  color: white !important;
}

.white-bg {
  background-color: #f9fafb !important;
}

.white-text {
  color: white !important;
}

.gif {
  padding-top: 10%;
  padding-left: 35%;
  height: 100% !important;
  width: 100% !important;
}

.orteke {
  background-color: #2a2a2a !important;
  color: white !important;
}

.description {
  font-size: 18px;
}

.howto {
  padding-top: 100px;
}

.hidden-scroll {
  overflow-y: scroll;
  height: 100vh !important;
}

.hidden-scroll::-webkit-scrollbar {
  display: block;
}

.hidden-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ozan {
  height: 100vh;
  background-image: url("https://cdnb.artstation.com/p/assets/images/images/054/992/877/original/cem-kiy-idle.gif?1665856863");
  background-repeat: no-repeat;
  background-size: 70vh 125vh;
  background-position: center;
}